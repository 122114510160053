import { styled, media } from '@creditas-ui/react'
import { LazyImage } from 'components/LazyImage'
import { Colors } from 'utils/colors';

export const Container = styled.section`
  background: #ffff;
  width: 100%;
`

export const OpinionsContainer = styled.div`
  padding: 48px 20px;
  margin: 0 auto;
  max-width: 1366px;

  ${media.up('3xl')} {
    padding: 96px 20px 72px;
  }

  ${media.up('5xl')} {
    padding: 96px 11.5%;
  }
`

export const ListOpinionsContainer = styled.div`
  padding: 0;
`

export const OpinionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  ${media.up('5xl')} {
    flex-direction: row;
  }
`

export const LeftContainer = styled.div`
  display: none;
  margin: 0 128px 0 0;
  max-width: 472px;
  height: 412px;

  ${media.up('5xl')} {
    display: block;
  }
`

export const ImageContainer = styled(LazyImage)`
  width: 392px;
  height: 100%;
  border-radius: 20px;
  animation: fadeInLeft 0.5s ease-out forwards;

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-30px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  ${media.down('4xl')} {
    min-height: 284px;
    max-height: 500px;
  }
`

export const RightContainer = styled.div`
  padding: 0;
`

export const TitleOpinionsContainer = styled.div`
  min-width: 320px;
  margin: 0 0 24px 0;

  ${media.up('5xl')} {
    margin: 0 0 24px 0;
    width: 472px;
  }
`

export const OpinionBody = styled.div`
  padding: 0;
`

export const OpinionText = styled.div`
  margin-bottom: 16px;
  overflow: visible;
`

export const Author = styled.div`
  margin-bottom: 53px;
`

export const AvatarsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const ChartContainer = styled.div`
  position: relative;
  margin-right: 16px;
`

export const Avatar = styled.img`
  margin-left: 4px;
  width: 52px;
  height: 52px;
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  filter: brightness(${props => (props.active ? 100 : 70)}%);
  border-radius: 100%;

  :hover {
    filter: none;
  }
`

export const CircularChart = styled.svg`
  display: block;
  width: 66px;
  height: 66px;
  position: absolute;
  bottom: -5px;
  left: -3px;
`

export const CirclePath = styled.path`
  fill: none;
  stroke-width: 1.5;
  stroke-linecap: round;
  animation: progress 6s linear forwards;
  stroke: ${Colors.Green};

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
`
